import { createRouter, createWebHistory } from 'vue-router'
const routes = [
    {
        path: '/',
        name: 'access',
        component: () => import('../views/vrAccess')
    },

]
const router = createRouter({
    history: createWebHistory(),
    routes
})
router.beforeEach(() => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    window.pageYOffset = 0
})
export default router



